import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '@components/Layout'
import { ApolloCPAContainer } from '@containers/ApolloCPAContainer'
import { parseQueryString } from '@utils'
import Redirect from '@components/Redirect'

/**
 *
 * Testing purposes
 * Preview links spacecamp
 * Home - us
 * http://localhost:8000/preview?t=spacecamp&id=Ie2GXTaN0PCfAQ7Si0rTB&contentType=page
 *
 * Case studies
 * http://localhost:8000/preview/?t=spacecamp&id=3hGr6029pszi5fa1pga1Bb&contentType=page
 *
 *
 * Capabilities
 * http://localhost:8000/preview/?t=spacecamp&id=2sdFrnT1CTTRriTS2QHOIQ&contentType=page
 *
 * Article - lorem ipsum dolor
 * http://localhost:8000/preview?t=spacecamp&id=1mCxOELq4wB0EygqWc4vaL&contentType=blogPost
 * http://localhost:8000/preview/?t=spacecamp&id=7EP31fx5eVfgzOAUSXPYQn&contentType=blogPost
 * http://localhost:8000/preview?t=spacecamp&id=5saEJsFlCc6elAnznMagSQ&contentType=blogPost
 *
 * Tag Index
 * http://localhost:8000/preview?t=spacecamp&id=3MZCRcQICu6ykcDCwk5s2b&contentType=blogTag
 *
 * Category Index
 * http://localhost:8000/preview?t=spacecamp&id=3wZXj7xUOTHMEtyj92XSL&contentType=blogCategory
 *
 *
 * [Sample] - Backgrounds
 * http://localhost:8000/preview/?t=space&id=4mpDGPsLqPCx4XkldMjeOL&contentType=page
 *
 *
 * Ayzenberg preview links
 * Home - us
 * http://localhost:8000/preview?t=spacecamp&id=Ie2GXTaN0PCfAQ7Si0rTB&contentType=page
 * http://localhost:8000/preview?t=ayzenberg&id=0q9FxBGkAEMHXjEVocD6M&contentType=page
 *
 *
 * Article - lorem ipsum dolor
 * http://localhost:8000/preview?t=ayzenberg&id=3bSxkONV7YZ4rNmQx8kDB5&contentType=article
 *
 * Tag Index
 * http://localhost:8000/preview?t=ayzenberg&id=Rf5pqiUMFi3wCMxPz5O5k&contentType=tag
 *
 * Category Index
 * http://localhost:8000/preview?t=spacecamp&id=3wZXj7xUOTHMEtyj92XSL&contentType=blogCategory
 *
 * Person Index
 * http://localhost:8000/preview?t=ayzenberg&id=4281hSHboNwmrqCMwp3Peg&contentType=person
 *
 */

const DEFAULT_HOME_PREVIEW_ID = 'Ie2GXTaN0PCfAQ7Si0rTB'

const Preview = ({ pageContext, location }) => {
  const [pageId, setPageId] = useState(DEFAULT_HOME_PREVIEW_ID)
  const [contentTypeId, setContentTypeId] = useState('page')
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const accessToken = parseQueryString('t')
      if (accessToken === process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
        setAuth(true)
        setLoading(false)
      } else {
        setAuth(false)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && auth) {
      const id = parseQueryString('id')
      const contentTypeId = parseQueryString('contentType') || 'page'
      const pageNumber = Number(parseQueryString('pageNumber'))
      setPageNumber(pageNumber || 1)
      setContentTypeId(contentTypeId)
      setPageId(id)
    }
  }, [auth, location])

  if (loading) {
    return (
      <Layout {...pageContext} location={location} pageSEO theme="blue">
        <div />
      </Layout>
    )
  }

  if (!loading) {
    if (!auth) {
      return <Redirect to="/" />
    } else {
      return (
        <Layout
          {...pageContext}
          location={location}
          pageSEO
          theme="blue"
          header
          footer
          preview
        >
          <ApolloCPAContainer
            location={location}
            id={pageId}
            contentTypeId={contentTypeId}
            pageNumber={pageNumber}
          />
        </Layout>
      )
    }
  }
}

Preview.propTypes = {
  pageContext: PropTypes.object,
}

export default Preview
