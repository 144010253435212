import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledGridItemContainer,
  StyledCaption,
  StyledOverlayShadow,
  StyledOverlayText,
} from './styles'
import Image from '@components/Image'
import Box from '@components/Box'

import { Logo } from '../Button'

const MyColorGridItem = ({
  name,
  title,
  slug,
  preview = false,
  shortBio,
  image = null,
  alt,
  myColor,
  ...rest
}) => {
  shortBio = shortBio && shortBio.split('\n')[0]

  return (
    <StyledGridItemContainer width="100%">
      <Logo to={slug}>
        <StyledGridItemContainer position="relative">
          <Box
            position="relative"
            bg="white"
            borderStyle="solid"
            borderColor={`myColor.${myColor.toLowerCase()}`}
            borderWidth="5px"
          >
            <StyledOverlayShadow />
            <Image {...image} />
          </Box>
          <StyledCaption
            position="absolute"
            bottom="0"
            color="white"
            ml={3}
            fontSize={2}
          >
            {name} | {title}
          </StyledCaption>

          <StyledOverlayText p={3}>{shortBio}</StyledOverlayText>
        </StyledGridItemContainer>
      </Logo>
    </StyledGridItemContainer>
  )
}

MyColorGridItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  shortBio: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default MyColorGridItem
