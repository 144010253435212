import React, { useEffect, useState, useCallback } from 'react'
import { ModuleApolloCPAContainer } from '@containers/ModuleContainer'
import usePreviewQuery, { GET_ENTRY_BY_ID } from '@hooks/usePreviewQuery'
import { ArticleApolloCPAContainer } from '@containers/Article'
import { IndexPageApolloCPAContainer } from '@containers/Index'
import {
  getLinkedEntry,
  getArryOfIds,
  getLinkedEntryAndSpreadFields,
} from '@utils/'
import { useLocale } from '@hooks/'
import { PeopleApolloCPAContainer } from '@containers/People'
import { LoginApolloCPAContainer } from '@containers/Login'

const Preview = ({ id, contentTypeId, pageNumber, location }) => {
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY_BY_ID,
    id: id,
    skip: !id,
    locale: locale,
  })
  const [modules, setModules] = useState([])

  const handleGetImageVideoOrSolidColor = (arr, targetId, assetArr) => {
    let imageVideoOrSolidColor = getLinkedEntryAndSpreadFields(arr, targetId)
    if (imageVideoOrSolidColor?.thumbnail) {
      imageVideoOrSolidColor.thumbnail = getLinkedEntryAndSpreadFields(
        assetArr,
        imageVideoOrSolidColor.thumbnail.sys.id
      )
    }
    if (imageVideoOrSolidColor?.sourceLandscape) {
      imageVideoOrSolidColor.sourceLandscape = getLinkedEntryAndSpreadFields(
        assetArr,
        imageVideoOrSolidColor.sourceLandscape.sys.id
      )
      if (imageVideoOrSolidColor.sourcePortrait) {
        imageVideoOrSolidColor.sourcePortrait = getLinkedEntryAndSpreadFields(
          assetArr,
          imageVideoOrSolidColor.sourcePortrait.sys.id
        )
      }
    }
    return imageVideoOrSolidColor
  }

  const handleGradient = (arr, targetId, assetArr) => {
    let gradient = getLinkedEntryAndSpreadFields(arr, targetId)
    if (gradient?.colors) {
      gradient.colors = gradient.colors.map(color =>
        getLinkedEntryAndSpreadFields(arr, color.sys.id)
      )
    }
    return gradient
  }

  const getLinkedEntryAssetAndSpreadFields = useCallback(
    (arr, targetId, assetArr) => {
      let entry = getLinkedEntryAndSpreadFields(arr, targetId)

      if (entry?.imageVideoOrSolidColor?.[0]?.sys?.id) {
        entry.imageVideoOrSolidColor = handleGetImageVideoOrSolidColor(
          arr,
          entry.imageVideoOrSolidColor[0].sys.id,
          assetArr
        )
      }
      if (entry?.gradient?.sys) {
        entry.gradient = handleGradient(arr, entry.gradient.sys.id)
      }
      if (entry?.source) {
        entry.source = getLinkedEntryAndSpreadFields(arr, entry.source.sys.id)
      }

      return entry
    },
    []
  )

  const handleMapContentItemAndContentGroups = useCallback(
    (item, entryIncludes, assetIncludes) => {
      let { fields, sys } = item
      const isContentItem = sys.contentType.sys.id === 'moduleBackgroundBanner'
      const isGallery = sys.contentType.sys.id === 'moduleGallery' // imagevideogallery

      if (isContentItem) {
        return {
          ...fields,
          background: getLinkedEntryAssetAndSpreadFields(
            entryIncludes,
            fields.background.sys.id,
            assetIncludes
          ),
          bodyPadding: getLinkedEntryAssetAndSpreadFields(
            entryIncludes,
            fields?.bodyPadding?.sys?.id,
            assetIncludes
          ),
          id: sys.id,
          type: sys.contentType.sys.id,
        }
      } else if (isGallery) {
        return {
          ...fields,
          items: fields?.items?.map(item =>
            getLinkedEntryAssetAndSpreadFields(
              entryIncludes,
              item?.sys?.id,
              assetIncludes
            )
          ),
          type: sys.contentType.sys.id,
        }
      } else {
        return {
          ...fields,
          items: fields?.items?.[0].sys.id
            ? getLinkedEntryAssetAndSpreadFields(
                entryIncludes,
                fields?.items?.[0].sys.id,
                assetIncludes
              )
            : null,
          feedSource: fields?.feedSource?.sys.id
            ? getLinkedEntryAssetAndSpreadFields(
                entryIncludes,
                fields?.feedSource?.sys.id,
                assetIncludes
              )
            : null,
          cards:
            fields?.cards &&
            fields.cards.map(card =>
              getLinkedEntryAssetAndSpreadFields(
                entryIncludes,
                card.sys.id,
                assetIncludes
              )
            ),
          id: sys.id,
          type: sys.contentType.sys.id,
        }
      }
    },
    [getLinkedEntryAssetAndSpreadFields]
  )

  const mapModulesToPage = useCallback(
    (page, entryIncludes, assetIncludes) => {
      const moduleIds =
        page?.[0]?.fields?.modules && getArryOfIds(page[0].fields.modules)

      const modules = moduleIds
        ?.map(id => getLinkedEntry(entryIncludes, id))
        ?.filter(module => module)
        ?.map(item =>
          handleMapContentItemAndContentGroups(
            item,
            entryIncludes,
            assetIncludes
          )
        )

      return modules
    },
    [handleMapContentItemAndContentGroups]
  )

  useEffect(() => {
    if (!loading && !error && data) {
      const {
        entry: { includes, items },
      } = data
      let page = items
      const Entry = includes?.Entry || []
      const Asset = includes?.Asset || []
      switch (contentTypeId) {
        case 'person':
        case 'category':
        case 'tag':
        case 'article':
          setModules(data.entry)
          break
        case 'page':
        default:
          let mappedModules = mapModulesToPage(page, Entry, Asset)
          setModules(mappedModules)
      }
    }
  }, [loading, data, error, mapModulesToPage, contentTypeId])

  if (!id && contentTypeId === 'person') return <PeopleApolloCPAContainer />
  switch (contentTypeId) {
    case 'article':
      return <ArticleApolloCPAContainer {...modules} location={location} />
    case 'page':
      return <ModuleApolloCPAContainer modules={modules} />
    case 'person':
      return (
        <IndexPageApolloCPAContainer
          {...modules}
          pageNumber={pageNumber}
          paginate={8}
        />
      )
    case 'category':
    case 'tag':
      return (
        <IndexPageApolloCPAContainer {...modules} pageNumber={pageNumber} />
      )
    case 'login':
      return <LoginApolloCPAContainer {...modules} location={location} />
    default:
      return null
  }
}

export default Preview
