import React from 'react'
import PropTypes from 'prop-types'

import MyColorGrid from '@components/MyColorGrid'
import Box from '@components/Box'

const People = ({ people }) => {
  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Box width="100%">
        <MyColorGrid title="Our Team" galleryItems={people} />
      </Box>
    </Box>
  )
}

People.propTypes = {}

export default People
