import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocale, useMobile } from '@hooks/'
import People from '@components/People'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@hooks/usePreviewQuery'
import { recursivelyGetLinkedEntriesAndSpreadFields, parseImage } from '@utils/'

const PeopleApolloCPAContainer = props => {
  const { locale } = useLocale()
  const [people, setPeople] = useState([])
  const [isMobile] = useMobile()
  const IS_SPACECAMP = process.env.GATSBY_SPACECAMP || false

  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'person',
    locale,
  })

  const getMappedItems = useCallback((person, entryIncludes, assetIncludes) => {
    person = {
      ...person?.fields,
      id: person?.sys?.id,
      type: person?.sys?.contentType?.sys?.id,
    }
    return recursivelyGetLinkedEntriesAndSpreadFields(
      person,
      entryIncludes,
      assetIncludes
    )
  }, [])

  useEffect(() => {
    if (!loading && !error && data) {
      let people = data?.entries?.items
      people = people?.map(person =>
        getMappedItems(
          person,
          data?.entries?.includes?.Entry,
          data?.entries?.includes?.Asset
        )
      )

      people = people?.filter(
        person =>
          person &&
          person?.isVisibleOnANetworkPeoplePage &&
          (IS_SPACECAMP
            ? person?.category?.title === 'space.camp' ||
              person?.category?.id === '5iZSUBh53LKI5O3Tn3Jvdi' // space.camp category id
            : true)
      )

      people = people?.map(person => ({
        ...person,
        shortBio: person?.excerpt,
        image: parseImage(person?.image),
        alt: !isMobile
          ? person?.image?.sourceLandscape?.description ||
            person?.image?.sourceLandscape?.title
          : person?.image?.sourcePortrait?.description ||
            person?.image?.sourcePortrait?.title,
      }))

      setPeople(people)
    }
  }, [IS_SPACECAMP, data, error, getMappedItems, isMobile, loading])
  return <People people={people} title="The Players" />
}

PeopleApolloCPAContainer.propTypes = {}

export default PeopleApolloCPAContainer
