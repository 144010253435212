import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledGridContainer,
  StyledGridWrapper,
  StyledTitle,
  StyledBackground,
} from './styles'
import MyColorGridItem from './MyColorGridItem'

const MyColorGrid = ({ title, galleryItems = [] }) => {
  return (
    <StyledBackground bg="white" data-testid="my-color-grid">
      <StyledGridWrapper margin="0 auto">
        {/* <StyledTitle fontSize={3} m={0} p={3} color={`${theme}.text`}>
          {title}
        </StyledTitle> */}
        <StyledGridContainer
          display="grid"
          gridColumnGap="16px"
          gridRowGap="16px"
          gridTemplateColumns={[
            '100%',
            'repeat(3, minmax(0, 1fr))',
            'repeat(3, minmax(0, 1fr))',
          ]}
          mb={3}
        >
          {galleryItems.map(({ id, shortBio, ...rest }) => (
            <MyColorGridItem key={id} shortBio={shortBio} {...rest} />
          ))}
        </StyledGridContainer>
      </StyledGridWrapper>
    </StyledBackground>
  )
}

MyColorGrid.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  galleryItems: PropTypes.arrayOf(PropTypes.object),
}

export default MyColorGrid
